import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import Account from "./pages/Account";
import Security from "./pages/Security";
import PageNotFound from "./pages/PageNotFound";
import "./i18n";
import {AuthRoute} from "./Auth";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<AuthRoute> <Layout /> </AuthRoute>}>
          <Route index element={<Account />} />
          <Route path="account" element={<Account />} />
          <Route path="security" element={<Security />} />
          <Route path="*" element={<PageNotFound
           />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

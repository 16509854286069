import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            personalDetails: "Personal details",
            security: "Account Security",
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email",
            accountSecurity: "Account security",
            password: "Password",
            passwordDescription: "If you want to change your password click on the link below",
            changePassword: "Change password",
            mfa: "Two factor authentication",
            mfaDescription: "Two-factor authentication is an enhanced security measure. Once enabled, you'll be required to give two types of identification when you log in to ReconHub. Security apps, such as Google Authenticator, Microsoft Authenticator or Authy are supported.",
            setMfa: "Enable two factor authentication",
            removeOtp: "Delete",
            menu: "menu_",
            connectedDevice: "Connected device",
            documentTitle: "Reconhub | Auth",
            language: "Language",
            doSave: "Save",
            doCancel: "Cancel",
            doSignOut: "Sign out",
            en: "English",
            de: "German",
            fr: "French",
            accountUpdatedSuccess: "Your account was successfully updated.",
            accountUpdatedError: "There was an error while trying to update the account. Please try later again."
        },
      },
      de: {
        translation: {
            personalDetails: "Persönliche Daten",
            security: "Kontosicherheit",
            firstName: "Vorname",
            lastName: "Nachname",
            email: "Email",
            accountSecurity: "Kontosicherheit",
            password: "Passwort",
            passwordDescription: "Falls Sie ihr Passwort ändern wollen, klicken Sie auf den folgenden Link",
            changePassword: "Passwort ändern",
            mfa: "Zwei Faktor Authentizierung",
            mfaDescription: "Die Zwei-Faktor-Authentifizierung ist eine verbesserte Sicherheitsmaßnahme. Sobald sie aktiviert ist, müssen Sie beim Anmelden bei ReconHub zwei Arten der Identifikation angeben. Sicherheits-Apps wie Google Authenticator, Microsoft Authenticator oder Authy werden unterstützt.",
            setMfa: "Zwei Faktor Authentizierung einrichten",
            removeOtp: "Löschen",
            menu: "menu_",
            connectedDevice: "Verknüpftes Gerät",
            documentTitle: "Reconhub | Auth",
            language: "Sprache",
            doSave: "Speichern",
            doCancel: "Abbrechen",
            doSignOut: "Abmelden",
            en: "Englisch",
            de: "Deutsch",
            fr: "Französisch",
            accountUpdatedSuccess: "Ihr Konto wurde erfolgreich aktualisiert.",
            accountUpdatedError: "Beim Versuch, das Konto zu aktualisieren, ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
        }
        },
      fr: {
        translation: {
            personalDetails: "Données personnelles",
            security: "Sécurité de compte",
            firstName: "Prénom",
            lastName: "Nom",
            email: "E-mail",
            accountSecurity: "Sécurité de compte",
            password: "Mot de passe",
            passwordDescription: "Si vous souhaitez changer votre mot de passe, cliquez sur le lien suivant",
            changePassword: "Changer le mot de passe",
            mfa: "Authentification à deux facteurs",
            mfaDescription: "L'authentification à deux facteurs permet un niveau de sécurité amélioré. Dès qu'elle est activée, vous devez utiliser deux facteurs d'authentification pour vous connecter à ReconHub. Des applications d'authentification comme Google Authenticator, Microsoft Authenticator ou encore Authy sont supportées.",
            setMfa: "Configurer l'authentification à deux facteurs",
            removeOtp: "Supprimer",
            menu: "menu_",
            connectedDevice: "Appareils reliés",
            documentTitle: "Reconhub | Auth",
            language: "Langue",
            doSave: "Enregistrer",
            doCancel: "Annuler",
            doSignOut: "Déconnexion",
            en: "Anglais",
            de: "Allemand",
            fr: "Français",
            accountUpdatedSuccess: "Votre compte a été mis à jour avec succès.",
            accountUpdatedError: "Une erreur s'est produite lors de la tentative de mise à jour du compte. Veuillez réessayer plus tard."
        }
      }
    }
  });

export default i18n;